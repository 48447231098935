<template>
  <b-badge :variant="getVariant(value)">
    <span>{{ getTextValue(value) }}</span
    ><b-badge class="ml-50" pill>{{ value }}</b-badge>
  </b-badge>
</template>

<script>
import { BBadge } from "bootstrap-vue";

export default {
  components: {
    BBadge,
  },

  props: {
    value: {
      required: true,
      type: String,
    },
    colorsReversed: {
      required: false,
      default: false,
    },
    title: {
      required: true,
      type: String,
    },
  },

  methods: {
    getVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return this.colorsReversed ? "danger" : "success";
        case 2:
          return this.colorsReversed ? "warning" : "info";
        case 3:
          return "primary";
        case 4:
          return this.colorsReversed ? "info" : "warning";
        case 5:
          return this.colorsReversed ? "success" : "danger";
        default:
          return "success";
      }
    },

    getTextValue(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return this.colorsReversed ? "Very High" : "Very Low";
        case 2:
          return this.colorsReversed ? "High" : "Low";
        case 3:
          return "Medium";
        case 4:
          return this.colorsReversed ? "Low" : "High";
        case 5:
          return this.colorsReversed ? "Very Low" : "Very High";
        default:
          return "Very Low";
      }
    },
    getLightVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return this.colorsReversed ? "light-danger" : "light-success";
        case 2:
          return this.colorsReversed ? "light-warning" : "light-info";
        case 3:
          return "light-primary";
        case 4:
          return this.colorsReversed ? "light-info" : "light-warning";
        case 5:
          return this.colorsReversed ? "light-success" : "light-danger";
        default:
          return "light-success";
      }
    },

    getBGLightVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return this.colorsReversed ? "bg-light-danger" : "bg-light-success";
        case 2:
          return this.colorsReversed ? "bg-light-warning" : "bg-light-info";
        case 3:
          return "bg-light-primary";
        case 4:
          return this.colorsReversed ? "bg-light-info" : "bg-light-warning";
        case 5:
          return this.colorsReversed ? "bg-light-success" : "bg-light-danger";
        default:
          return "bg-light-success";
      }
    },
    getTextVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return this.colorsReversed ? "text-danger" : "text-success";
        case 2:
          return this.colorsReversed ? "text-warning" : "text-info";
        case 3:
          return "text-primary";
        case 4:
          return this.colorsReversed ? "text-info" : "text-warning";
        case 5:
          return this.colorsReversed ? "text-success" : "text-danger";
        default:
          return "text-success";
      }
    },
  },
};
</script>

<style>
</style>