<template>
  <comments-section
    :comments="comments"
    :showCommentLoading="showOverlay"
    :disableCreation="false"
    :newCommentCallback="
      (comment) => {
        return addCommentAndSetData(registerId, comment);
      }
    "
  />
</template>

<script>
import CommentsSection from "@/components/CommentsSection.vue";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import { BOverlay } from "bootstrap-vue";
export default {
  mixins: [RiskMixins, ResponseMixins],
  components: { CommentsSection, BOverlay },

  data() {
    return {
      showOverlay: false,
      comments: [],
    };
  },

  props: {
    registerId: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getCommentsAndSetData(this.registerId);
    },

    addCommentAndSetData(riskId, comment) {
      this.showOverlay = true;
      return new Promise((resolve, reject) => {
        this.createRiskComment(riskId, comment)
          .then((res) => {
            this.handleResponse(res);
            this.load();
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
            reject(err);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      });
    },

    // createRiskCommentAndSetData(riskId, message) {
    //   this.showOverlay = true;
    //   this.createRiskComment(riskId, message)
    //     .then((res) => {
    //       console.log(res);
    //       this.handleResponse(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.handleError(err);
    //     })
    //     .finally(() => {
    //       this.showOverlay = false;
    //     });
    // },

    getCommentsAndSetData(riskId) {
      this.showOverlay = true;
      this.getRiskDiscussions(riskId)
        .then((res) => {
          console.log("Comments: ", res);
          this.comments = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>