<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card" style="box-shadow: none !important">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 80%"
          class="d-flex flex-column justify-content-center align-items-start"
          v-if="risk.risk && risk.risk.name && risk.risk.name !== null"
        >
          <h4 class="mb-0">Risk Details</h4>
          <!-- <h4 class="font-weight-bolder">
            <span class="align-middle">{{ risk.risk.name }}</span>
          </h4>
          <p class="card-subtitle text-muted pt-75">
            {{ risk.risk.description }}
          </p> -->
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
        <!-- <div
          class="d-flex align-items-center justify-content-between w-100 mt-1"
        >
          <div
            class="d-flex flex-row align-items-center justify-content-start"
            v-if="risk.asset_category && risk.asset_category !== null"
          >
            <p class="mb-0 font-weight-bolder">Category:</p>
            <b-badge variant="primary" class="ml-50">{{
              risk.asset_category ? risk.asset_category.name : ""
            }}</b-badge>
          </div>
        </div> -->
      </div>
      <div
        class="card-body pt-1 d-flex flex-column align-items-start justify-content-start w-100"
      >
        <div class="d-flex justify-content-end w-100">
          <b-button
            size="sm"
            variant="info"
            @click="handleRiskRegisterDetailsClick(risk._id, risk.risk_id)"
            style="float: right"
            ><feather-icon icon="ExternalLinkIcon" class="mr-50" />More
            Details</b-button
          >
        </div>

        <div
          class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
        >
          <h6 class="text-primary">Name</h6>
          <div
            class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
          >
            <div
              class="d-flex flex-column align-items-start justify-content-start w-100"
            >
              <p
                class="font-weight-bold mb-0"
                v-if="
                  risk.risk &&
                  risk.risk != null &&
                  risk.risk.name &&
                  risk.risk.name != null
                "
              >
                <span class="align-middle">{{ risk.risk.name }}</span>
              </p>
              <small
                class="mt-75"
                v-if="
                  risk.risk &&
                  risk.risk != null &&
                  risk.risk.description &&
                  risk.risk.description != null
                "
              >
                {{ risk.risk.description }}
              </small>
            </div>
          </div>
        </div>

        <b-card align="center" class="bg-dark-blue h-100 w-100">
          <div class="row">
            <div class="col">
              <b-list-group class="list-group-circle text-left bg-transparent">
                <b-list-group-item class="bg-transparent">
                  <b>Category:</b>
                  <span>
                    <b-badge variant="primary" class="ml-1">{{
                      risk.asset_category ? risk.asset_category.name : ""
                    }}</b-badge></span
                  >
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <div class="d-flex align-items-center justify-content-start">
                    <b>Risk Score:</b>
                    <span
                      class="ml-1 d-flex align-items-center justify-content-start"
                    >
                      <b-badge
                        v-if="
                          risk.risk_value &&
                          risk.risk_value != null &&
                          risk.risk_value != ''
                        "
                        style="width: 120px"
                        :variant="getMappedVariant(risk.risk_value)"
                        >{{ getMappedTextValue(risk.risk_value) }}
                        <b-badge pill class="ml-25" size="sm">{{
                          risk.risk_value
                        }}</b-badge></b-badge
                      >

                      <b-badge
                        v-else-if="
                          risk.assessment_values.risk_value &&
                          risk.assessment_values.risk_value != null &&
                          risk.assessment_values.risk_value != ''
                        "
                        style="width: 120px"
                        :variant="
                          getMappedVariant(risk.assessment_values.risk_value)
                        "
                        >{{
                          getMappedTextValue(risk.assessment_values.risk_value)
                        }}
                        <b-badge pill class="ml-25" size="sm">{{
                          risk.assessment_values.risk_value
                        }}</b-badge></b-badge
                      >
                    </span>
                  </div>
                </b-list-group-item>
                <b-list-group-item class="bg-transparent">
                  <div class="d-flex align-items-center justify-content-start">
                    <b>Residual Risk Score:</b>
                    <span
                      class="ml-1 d-flex align-items-center justify-content-start"
                    >
                      <b-badge
                        v-if="
                          risk.residual_risk_value &&
                          risk.residual_risk_value != null &&
                          risk.residual_risk_value != ''
                        "
                        style="width: 120px"
                        :variant="getMappedVariant(risk.residual_risk_value)"
                        >{{ getMappedTextValue(risk.residual_risk_value) }}
                        <b-badge pill class="ml-25" size="sm">{{
                          risk.residual_risk_value
                        }}</b-badge></b-badge
                      >

                      <b-badge
                        v-else-if="
                          risk.assessment_values.residual_risk_value &&
                          risk.assessment_values.residual_risk_value != null &&
                          risk.assessment_values.residual_risk_value != ''
                        "
                        style="width: 120px"
                        :variant="
                          getMappedVariant(
                            risk.assessment_values.residual_risk_value
                          )
                        "
                        >{{
                          getMappedTextValue(
                            risk.assessment_values.residual_risk_value
                          )
                        }}
                        <b-badge pill class="ml-25" size="sm">{{
                          risk.assessment_values.residual_risk_value
                        }}</b-badge></b-badge
                      >
                    </span>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </b-card>

        <div
          class="d-flex flex-column align-items-start justify-content-between mb-2 w-100"
        >
          <h6 class="text-primary">Threats</h6>
          <div
            class="rounded border d-flex align-items-center justify-content-between w-100"
            v-if="risk.threat"
          >
            <table role="table" class="table table-sm">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col"></th>
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col" class="text-center">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr>
                  <td role="cell">1</td>
                  <td role="cell">
                    {{ risk.threat ? risk.threat.name : "" }}
                  </td>
                  <td role="cell">
                    <div
                      class="d-flex align-items-center justify-content-center w-100"
                    >
                      <b-badge
                        v-if="
                          risk.threat.threat_value &&
                          risk.threat.threat_value != null &&
                          risk.threat.threat_value != ''
                        "
                        style="width: 120px"
                        :variant="getVariant(risk.threat.threat_value)"
                        >{{ getTextValue(risk.threat.threat_value) }}
                        <b-badge pill class="ml-25" size="sm">{{
                          risk.threat.threat_value
                        }}</b-badge></b-badge
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="d-flex flex-column align-items-start justify-content-between mb-2 w-100"
        >
          <h6 class="text-primary">Vulnerabilities</h6>
          <div
            class="rounded border d-flex align-items-center justify-content-between w-100"
            v-if="risk.vulnerabilities && risk.vulnerabilities != null"
          >
            <table role="table" class="table table-sm">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col"></th>
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col" class="text-center">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template
                  v-if="
                    risk.vulnerabilities &&
                    risk.vulnerabilities != null &&
                    risk.vulnerabilities.length > 0
                  "
                >
                  <tr
                    role="row"
                    :key="i"
                    v-for="(vuln, i) in risk.vulnerabilities"
                  >
                    <td role="cell">
                      {{ i + 1 }}
                    </td>
                    <td role="cell">
                      {{ vuln.name }}
                    </td>
                    <td role="cell">
                      <div
                        class="d-flex align-items-center justify-content-center w-100"
                      >
                        <b-badge
                          style="width: 120px"
                          :variant="getVariant(vuln.vulnerability_value)"
                          >{{ getTextValue(vuln.vulnerability_value) }}
                          <b-badge pill class="ml-25" size="sm">{{
                            vuln.vulnerability_value
                          }}</b-badge></b-badge
                        >
                      </div>
                    </td>
                  </tr>
                </template>

                <template v-else>
                  <empty-table-section title="No Vulnerabilities">
                    <template #content>
                      <p class="text-center">
                        It seems like there are no vulnerabilities at the
                        moment.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div
          class="d-flex flex-column align-items-center justify-content-between border rounded p-1 w-100 mb-2"
        >
          <div class="d-flex align-items-center justify-content-around w-100">
            <div
              class="d-flex flex-column align-items-center justify-content-start"
            >
              <h6 class="text-primary">Risk Score</h6>
              <div
                class="d-flex align-items-center justify-content-center"
                v-if="risk.assessment_values.risk_value"
              >
                <progress-bar
                  :value="risk.assessment_values.risk_value"
                  :min="1"
                  :max="100"
                />
              </div>
            </div>

            <div
              class="d-flex flex-column align-items-center justify-content-start"
            >
              <h6 class="text-primary">Residual Risk Score</h6>
              <div
                class="d-flex align-items-center justify-content-center"
                v-if="risk.assessment_values.residual_risk_value"
              >
                <progress-bar
                  :value="risk.assessment_values.residual_risk_value"
                  :min="1"
                  :max="100"
                />
              </div>
            </div>
          </div>
        </div> -->

        <div
          class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
          v-if="risk.risk.treatment_plan && risk.risk.treatment_plan !== null"
        >
          <h6 class="text-primary">Treatment Plan</h6>
          <div
            class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
          >
            <div class="d-flex align-items-start justify-content-start w-100">
              <p class="text-left font-weight-bold mb-0">
                {{ risk.risk.treatment_plan }}
              </p>
            </div>
          </div>
        </div>

        <!-- <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            mb-1
            w-100
          "
        >
          <h6>Controls</h6>
          <div
            class="
              rounded
              border
              d-flex
              flex-column
              align-items-start
              justify-content-start
              w-100
              p-1
            "
          ></div>
        </div> -->

        <div class="w-100 mt-2">
          <b-tabs content-class="mt-1" justified>
            <b-tab title="Controls" lazy active>
              <div
                class="w-100 h-auto cursor-pointer"
                @click="
                  () => {
                    $router.push({
                      name: 'risk-details',
                      params: { riskId: risk._id, registerId: risk.risk_id },
                      query: {
                        tabIndex: 1,
                      },
                    });
                  }
                "
              >
                <controls-mapping-section :registerId="risk.risk_id" />
              </div>
            </b-tab>
            <b-tab title="Tasks" lazy>
              <tasks :registerId="risk._id" />
              <!-- <div>
                <table role="table" class="table table-hover w-100">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col">Name</th>
                      <th role="columnheader" scope="col">Due Date</th>
                      <th role="columnheader" scope="col">Assignee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        risk &&
                        risk != null &&
                        risk.risk_actions &&
                        risk.risk_actions != null &&
                        risk.risk_actions.length > 0 &&
                        risk.risk_actions.every(
                          (action) => action.task && action.task != null
                        )
                      "
                    >
                      <template v-for="action in risk.risk_actions">
                        <tr
                          role="row"
                          :key="action._id"
                          v-if="
                            action &&
                            action !== null &&
                            action.task &&
                            action.task !== null
                          "
                          @click="
                            () => {
                              $router.push({
                                name: 'tasks',
                                query: {
                                  task_id: action.task._id,
                                },
                              });
                            }
                          "
                        >
                          <td role="cell" style="max-width: 330px">
                            <div
                              v-if="
                                action.task.title && action.task.title !== null
                              "
                              class="d-flex align-items-center justify-content-start w-100"
                              v-b-tooltip.hover.top.v-primary
                              :title="action.task.title"
                            >
                              <p class="mb-0 font-weight-bolder text-truncate">
                                {{ action.task.title }}
                              </p>
                            </div>
                          </td>

                          <td aria-colindex="3" role="cell">
                            <div
                              class="d-flex align-items-center justify-content-start"
                              v-if="
                                action.task.due_date &&
                                action.task.due_date !== null
                              "
                            >
                              <p class="mb-0 font-weight-bold">
                                {{ action.task.due_date | moment }}
                              </p>
                            </div>
                          </td>
                          <td
                            aria-colindex="3"
                            role="cell"
                            style="max-width: 160px"
                          >
                            <p
                              v-if="
                                action.task.assignee_user &&
                                action.task.assignee_user != null
                              "
                              class="mb-0 font-weight-bold text-truncate"
                              v-b-tooltip.hover.top.v-primary
                              :title="
                                action.task.assignee_user.firstname +
                                ' ' +
                                action.task.assignee_user.lastname
                              "
                            >
                              {{ action.task.assignee_user.firstname + " " }}
                              {{ action.task.assignee_user.lastname }}
                            </p>
                            <b-badge v-else variant="danger"
                              >Deleted User</b-badge
                            >
                          </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <empty-table-section title="Tasks Empty">
                        <template #content>
                          <p class="text-center">
                            It seems there are no tasks at the moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </div> -->
            </b-tab>
            <b-tab title="Discussions" lazy>
              <risk-comments-section :registerId="risk._id" />
            </b-tab>
            <b-tab title="Logs" lazy>
              <div
                class="w-100 h-auto cursor-pointer"
                @click="
                  () => {
                    $router.push({
                      name: 'risk-details',
                      params: { riskId: risk._id, registerId: risk.risk_id },
                      query: {
                        tabIndex: 4,
                      },
                    });
                  }
                "
              >
                <logs :registerId="risk._id" />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BBadge,
  BFormSelect,
  BFormTextarea,
  VBTooltip,
  BMedia,
  BCard,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BTab,
  BTabs,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import ScoreInput from "@/components/ScoreInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import RangeSlider from "../../../components/RangeSlider.vue";
import ProgressBar from "../../../components/ProgressBar.vue";
import ValueBadge from "../../../components/ValueBadge.vue";
import TaskMixins from "../../../mixins/TaskMixins";
import moment from "moment";
import ValueBadge2 from "@/components/ValueBadge2.vue";
import SmallProgressChart from "@/components/SmallProgressChart.vue";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import ControlsMappingSection from "../sections/ControlsMappingSection.vue";
import Logs from "../sections/LogsSection.vue";
import RiskCommentsSection from "../sections/RiskCommentsSection.vue";
import Tasks from "../RiskDetails/sections/Tasks.vue";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    required,
    VueAutosuggest,
    BForm,
    VueSlider,
    BBadge,
    RangeSlider,
    BFormTextarea,
    ScoreInput,
    ProgressBar,
    ValueBadge,
    BMedia,
    BCard,
    BCardBody,
    ValueBadge2,
    BListGroup,
    BListGroupItem,
    SmallProgressChart,
    BTab,
    BTabs,
    EmptyTableSection,
    ControlsMappingSection,
    Logs,
    RiskCommentsSection,
    Tasks,
  },
  data() {
    return {
      showOverlay: false,
      showCommentLoading: false,
      risk: {},
      riskRegister: {},
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [TaskMixins, RiskMixins, ResponseMixins],

  mounted() {
    this.load();
  },

  props: {
    closeSidebar: {
      required: true,
      type: Function,
    },
    // risk: {
    //   required: true,
    //   type: Object,
    // },
    risk_id: {
      required: true,
      type: String,
    },
    handleRiskRegisterDetailsClick: {
      required: true,
      type: Function,
    },
  },

  methods: {
    load() {
      this.getRiskRegisterDetailsAndSetData(this.risk_id);
    },

    getRiskRegisterDetailsAndSetData(registerId) {
      this.showOverlay = true;
      this.getRiskRegisterDetails(registerId)
        .then((res) => {
          console.log("Register Details: ", res);
          const register = res.data.data;
          this.risk = register;
          // if (register.risk && register.risk != null) {
          //   this.risk = register.risk;
          // }
          //const newRiskRegister = {}; // A single soruce of Data
          // Getting values from the Risk Assessment Response Object
          // if (
          //   (register.risk_assessment_response &&
          //     register.risk_assessment_response != null &&
          //     Object.keys(register.risk_assessment_response).length > 0) ||
          //   (register.asset_risk &&
          //     register.asset_risk != null &&
          //     Object.keys(register.asset_risk).length > 0)
          // ) {
          //   Object.keys(this.riskRegister).forEach((key) => {
          //     // First Checks for the keys in the Main Object
          //     // The Keys in this object are of topmost priority
          //     if (register[key] && register[key] != null) {
          //       newRiskRegister[key] = register[key];
          //     }
          //     // Else Checks for the keys in the risk_assessment_response
          //     else if (
          //       register.risk_assessment_response &&
          //       register.risk_assessment_response != null &&
          //       register.risk_assessment_response[key] &&
          //       register.risk_assessment_response[key] != null
          //     ) {
          //       newRiskRegister[key] = register.risk_assessment_response[key];
          //     }
          //     // Else Checks for the keys in the asset_risk
          //     else if (
          //       register.asset_risk &&
          //       register.asset_risk != null &&
          //       register.asset_risk[key] &&
          //       register.asset_risk[key] != null
          //     ) {
          //       newRiskRegister[key] = register.asset_risk[key];
          //     } else {
          //       newRiskRegister[key] = null;
          //     }
          //   });
          //   console.log("New Details: ", newRiskRegister);
          //   this.riskRegister = newRiskRegister;
          // }

          // if (
          //   register.relatedRiskAssets &&
          //   register.relatedRiskAssets != null
          // ) {
          //   this.assets = register.relatedRiskAssets;
          // }
          // if (
          //   register.risk &&
          //   register.risk != null &&
          //   register.risk.vulnerabilities &&
          //   register.risk.vulnerabilities != null
          // ) {
          //   this.vulnerabilities = register.risk.vulnerabilities;
          // }

          // if (
          //   register.risk &&
          //   register.risk != null &&
          //   register.risk.threat &&
          //   register.risk.threat != null
          // ) {
          //   this.threat = register.risk.threat;
          // }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-primary";
        case 4:
          return "light-warning";
        case 5:
          return "light-danger";
        default:
          return "light-success";
      }
    },

    getMappedTextValue(value) {
      const status = this.mapValue(Number(value));
      const newValue = Number(status);
      if (newValue >= 0 && newValue < 1) {
        return "Very Low";
      } else if (newValue >= 1 && newValue < 2) {
        return "Low";
      } else if (newValue >= 2 && newValue < 3) {
        return "Medium";
      } else if (newValue >= 3 && newValue < 4) {
        return "High";
      } else if (newValue >= 4 && newValue <= 5) {
        return "Very High";
      } else {
        return "Very Low";
      }
    },

    getMappedVariant(value) {
      const status = this.mapValue(Number(value));
      const newValue = Number(status);
      if (newValue >= 0 && newValue < 1) {
        return "light-success";
      } else if (newValue >= 1 && newValue < 2) {
        return "light-info";
      } else if (newValue >= 2 && newValue < 3) {
        return "light-primary";
      } else if (newValue >= 3 && newValue < 4) {
        return "light-warning";
      } else if (newValue >= 4 && newValue <= 5) {
        return "light-danger";
      } else {
        return "light-success";
      }
    },

    mapValue(value) {
      return this.mapRange(value, 0, 100, 1, 5);
    },

    mapRange(value, inMin, inMax, outMin, outMax) {
      return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },

    getTextValue(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Very High";
        default:
          return "Very Low";
      }
    },
  },
};
</script>

<style scoped>
.custom-list {
  min-height: 150px;
  max-height: 100px;
  overflow-y: auto;
}
.custom-list li:hover {
  color: #7367f0; /* Change the text color to blue on hover */
}
[dir] .list-group-item {
  background-color: #fff0 !important;
}
</style>
