<template>
  <div class="card" v-if="risk!==null">
    <div class="card-header">
      <div style="width: 70%">
        <h2 class="text-primary">{{ risk.risk.name }}</h2>
      </div>

      <div class="d-flex justify-content-end align-items-center">
        <b-button @click="closeSidebar()" variant="danger"
          ><feather-icon icon="XIcon"
        /></b-button>
      </div>
    </div>
    <div class="card-body">
      <div
        class="w-100 mt-3 mb-1 d-flex justify-content-center align-items-center"
      >
        <h4 class="text-center">Tasks</h4>
      </div>

      <div class="main bg-light rounded py-2">
        <template v-for="action in risk.risk_actions">
          <div :key="action._id" class="item px-2 bg-white rounded cursor-pointer">
            <p class="content-section text-primary font-weight-bold text-truncate mb-0">{{ action.task.title }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { BOverlay, BButton } from "bootstrap-vue";
export default {
  name: "RiskTaskHandleForm",
  components: {
    BOverlay,
    BButton,
  },
  props: {
    risk: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  mounted(){
    console.log(this.risk)
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .item {
    margin: 6px auto;
    width: 94%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .content-section{
        width: 94%;
        text-align: start;
    }
  }
}
</style>