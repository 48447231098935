<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <table role="table" class="table table-condensed w-100">
      <thead role="rowgroup">
        <tr role="row">
          <th role="columnheader" scope="col">Number</th>
          <th role="columnheader" scope="col">Control</th>
          <th role="columnheader" scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="filteredControls && filteredControls != null && filteredControls.length > 0">
          <template v-for="control in filteredControls">
            <tr role="row" :key="control._id">
              <td aria-colindex="3" role="cell">
                <b-badge variant="danger">{{ control.control_no }}</b-badge>
              </td>

              <td role="cell">
                {{ control.name }}
              </td>
              <td role="cell" style="width: 170px">
                <b-badge variant="primary">{{
                  getImplementationStatus(control.implementation_status)
                }}</b-badge>
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <empty-table-section title="Controls Empty">
            <template #content>
              <p class="text-center">
                It seems there are no controls at the moment.
              </p>
            </template>
          </empty-table-section>
        </template>
      </tbody>
    </table>
  </b-overlay>
</template>

<script>
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import { BFormCheckbox, BBadge, BOverlay } from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import RiskMixins from "@/mixins/RiskMixins";
export default {
  mixins: [RiskMixins, ResponseMixins],
  components: { EmptyTableSection, BFormCheckbox, BBadge, BOverlay },

  props: {
    registerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
      controls: [],
      selectedValue: false,
    };
  },

  computed: {
    filteredControls() {
      return this.controls.filter((c) => c.applicability_status === 1);
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getRiskControlsAndSetData(this.registerId);
    },

    getImplementationStatus(status) {
      switch (status) {
        case 1:
          return "Implemented";
        case 2:
          return "Partially Implemented";
        case 3:
          return "Not Implemented";
        case 4:
          return "Planned";
        default:
          return "Unknown";
      }
    },

    getRiskControlsAndSetData(riskId) {
      this.showOverlay = true;
      this.getRiskControls(riskId)
        .then((res) => {
          console.log("Risk Controls: ", res);
          this.controls = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSelectionValueChange(newValue, controlId) {
      this.$emit("on-control-selection-change", {
        value: newValue,
        id: controlId,
      });
    },
  },
};
</script>

<style>
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>