<template>
  <div class="w-100 h-auto">
    <!-- <b-modal
      ref="new-bulk-action-modal"
      hide-footer
      title="Perform Bulk Action"
      size="lg"
    >
      <bulk-action-form />
    </b-modal> -->

    <b-modal
      ref="bulk-action-modal"
      hide-footer
      :title="currentModal.title"
      size="lg"
    >
      <validation-observer ref="mymodal" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleBulkActionSubmit">
          <b-overlay
            :show="isModalLoading"
            opacity="0.17"
            blur="1rem"
            rounded="md"
            variant="secondary"
          >
            <b-form-group class="mb-0" label="Note" label-for="action-note">
              <template slot="label">
                Note <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Note"
                rules="required"
              >
                <b-form-textarea id="action-note" v-model="currentModal.note" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <template v-if="showTaskForm == true">
              <b-form-group
                class="mt-1"
                label="Task Name"
                label-for="task-title"
              >
                <template slot="label">
                  Task Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Task Name"
                  rules="required"
                >
                  <b-form-input
                    id="task-title"
                    v-model="currentModal.task.taskTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label="Description"
                label-for="task-description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <b-form-textarea
                    id="task-description"
                    v-model="currentModal.task.taskDescription"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label="Task Priority"
                label-for="task-priority"
              >
                <template slot="label">
                  Task Priority <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Task Priority"
                  rules="required"
                >
                  <b-form-select
                    v-model="currentModal.task.taskPriority"
                    :options="currentModal.task.taskPriorityOptions"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label="Assignee"
                label-for="task-assignee"
              >
                <template slot="label">
                  Asignee <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Assignee"
                  rules="required"
                >
                  <vue-autosuggest
                    :suggestions="currentModal.task.taskAssigneeOptions"
                    :limit="5"
                    v-model="currentModal.task.taskAssigneeName"
                    id="autosuggest__input"
                    :input-props="assigneeInputProps"
                    @input="getAssigneeBySearchPhrase"
                    @selected="onAssigneeSelected"
                  >
                    <template slot-scope="{ suggestion }">
                      <span
                        >{{ suggestion.item.firstname }}
                        {{ suggestion.item.lastname }}</span
                      >
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="mt-1" label="Due Date" label-for="due-date">
                <template slot="label">
                  Due Date <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Due Date"
                  rules="required"
                >
                  <b-form-datepicker
                    id="due-date"
                    v-model="currentModal.task.taskDueDate"
                    :min="minDueDate"
                  ></b-form-datepicker>
                  <small class="text-danger">{{
                    errors[0]
                  }}</small></validation-provider
                ></b-form-group
              >
            </template>
          </b-overlay>

          <b-button
            type="submit"
            variant="primary"
            class="ml-0 mt-75"
            :disabled="invalid"
          >
            <b-spinner
              v-if="isModalLoading"
              label="Loading..."
              small
              class="mr-50"
            />
            <feather-icon v-else icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Submit</span>
          </b-button>
          <!-- <b-button
            v-if="currentModal.type == 3 "
            @click.prevent="handleAddNewTaskClick"
            class="ml-1 mt-75"
            variant="success"
            ><feather-icon icon="PlusIcon" class="mr-50" />New Task</b-button
          > -->
        </b-form>
      </validation-observer>
    </b-modal>

    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
    >
      <RiskTaskHandleForm
        :risk="taskSelectedRisk"
        :closeSidebar="closeSidebar"
      />
    </b-sidebar>

    <b-sidebar
      id="sidebar-info"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="600px"
    >
      <risk-register-more-info-form
        v-if="selectedRisk && selectedRisk != null"
        :closeSidebar="closeInfoSidebar"
        :risk_id="selectedRisk._id"
        :handleRiskRegisterDetailsClick="handleRiskRegisterDetailsClick"
      />
    </b-sidebar>

    <div class="card">
      <div class="card-header">
        <div
          class="d-flex flex-column align-items-start justify-content-start mb-1 w-100"
        >
          <div class="w-100 row justify-content-between align-items-center">
            <div
              class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
            >
              <h3 class="mb-50 font-weight-bold">Risk Register</h3>
              <p class="mb-0">Manage all your risks.</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <div
                class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
              >
                <b-button
                  @click="
                    () => {
                      $router.push('/riskassessment');
                    }
                  "
                  class="mr-1"
                  size="sm"
                  variant="success"
                  >Go to Risk Assessments
                  <feather-icon icon="ArrowRightCircleIcon" class="ml-50"
                /></b-button>

                <b-button
                  @click="
                    () => {
                      $router.push(
                        '/riskassessment?openNewAssessmentModal=true'
                      );
                    }
                  "
                  size="sm"
                  variant="outline-success"
                  >New Risk Assessment
                  <feather-icon icon="PlusIcon" class="ml-50"
                /></b-button>
              </div>
            </div>
          </div>

          <div class="col mt-2 py-1 rounded border">
            <b-row class="d-flex justify-content-between mb-2">
              <h4 class="mx-2 text-dark font-weight-bold">
                Filters
                <feather-icon class="ml-55" size="20" icon="FilterIcon" />
              </h4>
              <b-button
                variant="outline-danger"
                class="mx-2"
                size="sm"
                @click="clearFilters"
                ><feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Clear Filters</span></b-button
              >
            </b-row>
            <b-row>
              <div class="col-sm-12">
                <div
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <b-form-group style="width: 23%">
                    <v-select
                      :class="{
                        highlighted:
                          highlightedFilters.includes('max_risk_value'),
                      }"
                      v-model="filters.max_risk_value"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="riskScoreOptions"
                      placeholder="--Risk Score Upto--"
                    />
                    <!-- <b-form-select
                      v-model="filters.max_risk_value"
                      :options="riskScoreOptions"
                    ></b-form-select> -->
                  </b-form-group>

                  <b-form-group style="width: 25%">
                    <v-select
                      :class="{
                        highlighted: highlightedFilters.includes(
                          'max_risk_residual_score'
                        ),
                      }"
                      v-model="filters.max_risk_residual_score"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="residualRiskScoreOptions"
                      placeholder="--Residual Risk Score Upto--"
                    />
                    <!-- <b-form-select
                      v-model="filters.max_risk_residual_score"
                      :options="residualRiskScoreOptions"
                    ></b-form-select> -->
                  </b-form-group>

                  <b-form-group style="width: 23%">
                    <v-select
                      :class="{
                        highlighted:
                          highlightedFilters.includes('risk_category'),
                      }"
                      v-model="filters.risk_category"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="riskCategoryOptions"
                      placeholder="--Risk Category--"
                    />
                    <!-- <b-form-select
                      v-model="filters.risk_category"
                      :options="riskCategoryOptions"
                    ></b-form-select> -->
                  </b-form-group>

                  <b-form-group style="width: 23%">
                    <v-select
                      :class="{
                        highlighted: highlightedFilters.includes(
                          'risk_mitigation_type'
                        ),
                      }"
                      v-model="filters.risk_mitigation_type"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :options="riskMitigationOptions"
                      placeholder="--Risk Mitigation--"
                    />
                    <!-- <b-form-select
                      v-model="filters.risk_mitigation_type"
                      :options="riskMitigationOptions"
                    ></b-form-select> -->
                  </b-form-group>
                </div>
              </div>
            </b-row>
            <b-row>
              <div class="app-fixed-search col-sm-12">
                <b-input-group
                  class="input-group-merge"
                  :class="{
                    highlighted: highlightedFilters.includes('search'),
                  }"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon
                      v-if="filters.search == null || filters.search == ''"
                      icon="SearchIcon"
                      class="text-muted"
                    />
                    <feather-icon
                      @click="
                        () => {
                          filters.search = null;
                        }
                      "
                      v-else
                      icon="XIcon"
                      class="text-danger cursor-pointer"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filters.search"
                    placeholder="Search name..."
                    debounce="500"
                    size="md"
                  />
                </b-input-group>
              </div>
            </b-row>
          </div>
        </div>
      </div>

      <div class="mx-2 mb-1">
        <!-- <div class="row">
          <h2 class="ml-2 text-dark font-weight-bold">All Risks</h2>
        </div> -->

        <!-- Filters -->

        <!-- Bulk Action -->
        <!-- <div class="row justify-content-start my-2">
          <b-dropdown
            class="ml-2"
            text="Bulk Action"
            variant="gradient-primary"
            size="md"
            block
            :disabled="
              (this.selectedRisks.length < 1 &&
                this.risksWithFiltersSeleted !== true) ||
              risks.length === 0
            "
          >
            <b-dropdown-item
              ><b-button
                size="sm"
                block
                variant="dark"
                @click="handleBulkTransferClick()"
                >Transfer
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="TruckIcon" /></b-button
            ></b-dropdown-item>

            <b-dropdown-item
              ><b-button
                size="sm"
                block
                variant="danger"
                @click="handleBulkAvoidClick()"
                >Avoid
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="SlashIcon" /></b-button
            ></b-dropdown-item>

            <b-dropdown-item
              ><b-button
                size="sm"
                block
                variant="success"
                @click="handleBulkAcceptClick()"
                >Accept
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="CheckCircleIcon" /></b-button
            ></b-dropdown-item>

            <b-dropdown-item
              ><b-button
                size="sm"
                block
                variant="info"
                @click="handleBulkMitigateClick()"
                >Mitigate
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="MinusCircleIcon" /></b-button
            ></b-dropdown-item>
          </b-dropdown>
        </div> -->

        <!-- Select Checkbox -->
        <div
          class="mt-50 d-flex align-items-center justify-content-between w-100"
        >
          <div class="d-flex align-items-center justify-content-start">
            <!-- <b-form-checkbox
                id="select-all"
                :disabled="risksWithFiltersSeleted"
                v-model="allRisksSelected"
                name="select-all-checkbox"
                v-on:change="handleBulkRiskSelection($event)"
              >
                Select All Risks
              </b-form-checkbox> -->

            <b-form-checkbox
              :disabled="!isFiltersActive || risks.length == 0"
              id="action-by-filter"
              v-model="risksWithFiltersSeleted"
            >
              Select All Matching Filters
            </b-form-checkbox>
          </div>

          <div
            class="d-flex align-items-center justify-content-end"
            id="disabled-wrapper"
          >
            <b-tooltip
              v-if="
                (this.selectedRisks.length < 1 &&
                  this.risksWithFiltersSeleted !== true) ||
                risks.length === 0
              "
              target="disabled-wrapper"
              triggers="hover"
              variant="warning"
            >
              Select one or more risks to perform these actions.
            </b-tooltip>
            <div class="d-flex align-items-center justify-content-end w-auto">
              <b-button
                class="mr-1"
                size="sm"
                :disabled="
                  (this.selectedRisks.length < 1 &&
                    this.risksWithFiltersSeleted !== true) ||
                  risks.length === 0
                "
                variant="dark"
                @click="handleBulkTransferClick()"
                >Transfer
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="ChevronsRightIcon"
              /></b-button>

              <b-button
                class="mr-1"
                size="sm"
                :disabled="
                  (this.selectedRisks.length < 1 &&
                    this.risksWithFiltersSeleted !== true) ||
                  risks.length === 0
                "
                variant="danger"
                @click="handleBulkAvoidClick()"
                >Avoid
                <feather-icon class="cursor-pointer ml-50" icon="SlashIcon"
              /></b-button>

              <b-button
                class="mr-1"
                size="sm"
                :disabled="
                  (this.selectedRisks.length < 1 &&
                    this.risksWithFiltersSeleted !== true) ||
                  risks.length === 0
                "
                variant="success"
                @click="handleBulkAcceptClick()"
                >Accept
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="CheckCircleIcon"
              /></b-button>

              <b-button
                size="sm"
                :disabled="
                  (this.selectedRisks.length < 1 &&
                    this.risksWithFiltersSeleted !== true) ||
                  risks.length === 0
                "
                variant="warning"
                @click="handleBulkMitigateClick()"
                >Mitigate
                <feather-icon
                  class="cursor-pointer ml-50"
                  icon="MinusCircleIcon"
              /></b-button>

              <!-- <b-dropdown
                class="ml-2"
                text="Bulk Action"
                variant="gradient-primary"
                size="md"
                :disabled="
                  (this.selectedRisks.length < 1 &&
                    this.risksWithFiltersSeleted !== true) ||
                  risks.length === 0
                "
              >
                <b-dropdown-item
                  ><b-button
                    size="sm"
                    block
                    variant="dark"
                    @click="handleBulkTransferClick()"
                    >Transfer
                    <feather-icon
                      class="cursor-pointer ml-50"
                      icon="TruckIcon" /></b-button
                ></b-dropdown-item>

                <b-dropdown-item
                  ><b-button
                    size="sm"
                    block
                    variant="danger"
                    @click="handleBulkAvoidClick()"
                    >Avoid
                    <feather-icon
                      class="cursor-pointer ml-50"
                      icon="SlashIcon" /></b-button
                ></b-dropdown-item>

                <b-dropdown-item
                  ><b-button
                    size="sm"
                    block
                    variant="success"
                    @click="handleBulkAcceptClick()"
                    >Accept
                    <feather-icon
                      class="cursor-pointer ml-50"
                      icon="CheckCircleIcon" /></b-button
                ></b-dropdown-item>

                <b-dropdown-item
                  ><b-button
                    size="sm"
                    block
                    variant="info"
                    @click="handleBulkMitigateClick()"
                    >Mitigate
                    <feather-icon
                      class="cursor-pointer ml-50"
                      icon="MinusCircleIcon" /></b-button
                ></b-dropdown-item>
              </b-dropdown> -->
            </div>
          </div>
        </div>
      </div>

      <b-overlay
        :show="showOverlay"
        opacity="0.17"
        blur="1rem"
        rounded="md"
        variant="secondary"
      >
        <table role="table" class="table table-hover table-condensed">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">
                <b-form-checkbox
                  id="select-all"
                  :disabled="isFiltersActive || risksWithFiltersSeleted"
                  v-model="allRisksSelected"
                  name="select-all-checkbox"
                  v-on:change="handleBulkRiskSelection($event)"
                >
                </b-form-checkbox>
              </th>
              <th role="columnheader" scope="col">ID</th>
              <th role="columnheader" scope="col">Name</th>
              <th role="columnheader" scope="col">Owner</th>
              <!-- <th role="columnheader" scope="col" class="text-center">
                  Category
                </th> -->
              <th role="columnheader" scope="col" class="text-center">
                Risk Score
              </th>
              <th role="columnheader" scope="col" class="text-center">
                Residual Risk Score
              </th>
              <th role="columnheader" scope="col">Identified At</th>
              <th role="columnheader" scope="col">Last Assessed</th>
              <th role="columnheader" scope="col" class="text-center">
                Actions
              </th>
            </tr>
          </thead>

          <tbody role="rowgroup">
            <template v-if="risks && risks.length > 0">
              <tr role="row" v-for="risk in risks" :key="risk._id">
                <td rolde="cell">
                  <b-form-checkbox
                    :disabled="isFiltersActive || risksWithFiltersSeleted"
                    v-model="risk.risk_selected"
                    @change="handleRiskSelection($event, risk._id)"
                    class="mr-25"
                  ></b-form-checkbox>
                </td>
                <td
                  role="cell"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <div
                    class="d-flex justify-content-evenly align-items-center w-100"
                  >
                    <span @click.stop>
                      <!-- <b-form-checkbox
                        :disabled="risksWithFiltersSeleted"
                        v-model="risk.risk_selected"
                        @change="handleRiskSelection($event, risk._id)"
                        class="mr-25"
                      ></b-form-checkbox> -->
                    </span>
                    <span
                      class="text-primary"
                      v-if="
                        risk.risk_register_ref_number &&
                        risk.risk_register_ref_number != null
                      "
                      >{{ risk.risk_register_ref_number }}</span
                    >
                  </div>
                </td>

                <td
                  role="cell"
                  style="max-width: 20vw; min-width: 15vw"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <p
                    class="font-weight-bold mb-0"
                    v-if="risk.risk && risk.risk !== null"
                  >
                    {{ risk.risk.name }}
                  </p>
                </td>

                <td
                  role="cell"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <span
                    v-if="risk.risk_owner_user && risk.risk_owner_user != null"
                    >{{
                      `${risk.risk_owner_user.firstname} ${risk.risk_owner_user.lastname}`
                    }}</span
                  >
                </td>

                <!-- <td role="cell">
                    <div
                      v-if="risk.asset_category"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <b-badge class="badge-glow" variant="primary">{{
                        risk.asset_category.name
                      }}</b-badge>
                    </div>
                  </td> -->

                <td
                  role="cell"
                  style="max-width: 120px"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <div
                    v-if="risk && risk.risk_value && risk.risk_value !== null"
                    class="d-flex justify-content-center align-items-center w-100"
                  >
                    <!-- <progress-bar
                      :value="risk.assessment_values.risk_value"
                      :min="1"
                      :max="100"
                    /> -->

                    <progress-bar-v-2
                      :value="risk.risk_value"
                      :min="1"
                      :max="100"
                    />
                  </div>
                </td>

                <td
                  role="cell"
                  style="max-width: 120px"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <div
                    v-if="
                      risk.residual_risk_value &&
                      risk.residual_risk_value != 'undefined'
                    "
                  >
                    <div class="d-flex justify-content-center w-100">
                      <progress-bar-v-2
                        :value="risk.residual_risk_value"
                        :min="1"
                        :max="100"
                      />
                    </div>
                  </div>
                </td>

                <td
                  role="cell"
                  style="max-width: 120px"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <span
                    v-if="
                      risk.risk_identified_date &&
                      risk.risk_identified_date != null
                    "
                    >{{ risk.risk_identified_date | moment }}</span
                  >
                </td>

                <td
                  role="cell"
                  @click="
                    handleRiskRegisterDetailsClick(risk._id, risk.risk_id)
                  "
                >
                  <span
                    v-if="
                      risk.risk_last_assessed_date &&
                      risk.risk_last_assessed_date != null
                    "
                    >{{ risk.risk_last_assessed_date | moment }}</span
                  >
                </td>

                <td role="cell">
                  <div
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <b-dropdown
                      :text="risk.action_select_box_text"
                      :variant="
                        getActionColor(
                          risk.risk_actions && risk.risk_actions.length > 0
                            ? risk.risk_actions[risk.risk_actions.length - 1]
                                .type
                            : 0
                        )
                      "
                      size="sm"
                      style="width: 140px"
                    >
                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          block
                          variant="dark"
                          @click.stop="handleTransferClick(risk._id)"
                          >Transfer
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="ChevronsRightIcon" /></b-button
                      ></b-dropdown-item>

                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          variant="danger"
                          block
                          @click.stop="handleAvoidClick(risk._id)"
                          >Avoid
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="SlashIcon" /></b-button
                      ></b-dropdown-item>

                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          block
                          variant="success"
                          @click.stop="handleAcceptClick(risk._id)"
                          >Accept
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="CheckCircleIcon" /></b-button
                      ></b-dropdown-item>

                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          block
                          variant="warning"
                          @click.stop="handleMitigateClick(risk._id)"
                          >Mitigate
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="MinusCircleIcon" /></b-button
                      ></b-dropdown-item>
                    </b-dropdown>
                    <!-- <b-button
                        @click.stop="openSidebar(risk._id)"
                        class="mt-25"
                        size="sm"
                        style="width: 140px"
                        variant="primary"
                        >View Tasks</b-button
                      > -->
                    <b-button
                      @click.stop="handleMoreInfoClick(risk)"
                      class="mt-25"
                      size="sm"
                      style="width: 140px"
                      variant="outline-success"
                      ><feather-icon icon="InfoIcon" class="mr-50" />Quick
                      View</b-button
                    >
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <empty-table-section title="Risks Not Found">
                <template #content>
                  <p class="font-weight-bold text-center">
                    It seems that you have no risks at the moment.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>
      </b-overlay>

      <div class="card-body" v-if="risks && risks.length > 0">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div>
            <p class="mb-0">
              Showing {{ from }}-{{ to }} out of {{ totalRisks }}
            </p>
          </div>
          <b-pagination
            v-model="filters.page"
            :per-page="perPage"
            :total-rows="rows"
            aria-controls="my-table"
            class="mb-0"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import moment from "moment";

import {
  BCard,
  BCardBody,
  BCol,
  BRow,
  BPagination,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
  BSidebar,
  VBTooltip,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ProgressBar from "../../components/ProgressBar.vue";
import ScoreInput from "../../components/ScoreInput.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import RiskMixins from "@/mixins/RiskMixins";
import { VueAutosuggest } from "vue-autosuggest";
import RiskTaskHandleForm from "./components/RiskTaskHandleForm.vue";
import RiskRegisterMoreInfoForm from "./components/RiskRegisterMoreInfoForm.vue";

import ResponseMixins from "../../mixins/ResponseMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import ProgressBarV2 from "../../components/ProgressBarV2.vue";
import RiskMixins from "../../mixins/RiskMixins";

export default {
  name: "RiskRegister",
  components: {
    BCard,
    BCardBody,
    ToastificationContent,
    Ripple,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BModal,
    BFormGroup,
    BForm,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    draggable,
    VuePerfectScrollbar,
    BOverlay,
    ProgressBar,
    ScoreInput,
    BSidebar,
    RiskTaskHandleForm,
    RiskRegisterMoreInfoForm,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BTooltip,
    EmptyTableSection,
    ProgressBarV2,
    BSpinner,
  },

  directives: {
    "b-tooltip": VBTooltip,
    highlight: {
      bind(el, binding) {
        if (binding.value) {
          el.classList.add("highlighted"); // Apply the highlight when the directive is bound
        }
      },
    },
  },
  mixins: [RiskMixins, ResponseMixins],

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  data() {
    return {
      isInitialLoad: true,
      highlightedFilters: [],
      highlightedFilter: null,
      filters: {
        search: null,
        max_risk_value: null,
        max_risk_residual_score: null,
        risk_category: null,
        risk_mitigation_type: null,
        page: 1,
        risk_id: null,
      },

      riskCategoryOptions: [
        { value: null, text: "--Risk Category--" },
        { value: "low", text: "Low" },
        { value: "medium", text: "Medium" },
        { value: "high", text: "High" },
        { value: "critical", text: "Critical" },
      ],

      riskMitigationOptions: [
        { value: null, text: "--Risk Mitigation--" },
        { value: 0, text: "Unattended" },
        { value: 1, text: "Avoided" },
        { value: 2, text: "Transferred" },
        { value: 3, text: "Accepted" },
        { value: 4, text: "Mitigated" },
      ],

      selectedRisk: null,
      timeout: null,
      debounceMilliseconds: 250,
      showOverlay: false,
      isModalLoading: false,
      showTaskForm: false,
      perPage: 10,
      from: 0,
      to: 0,
      totalRisks: null,
      rows: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      minDueDate: null,
      allRisksSelected: false,
      risksWithFiltersSeleted: false,
      selectedRisks: [],
      taskSelectedRisk: null,
      risks: null,
      expandedList: [],
      assigneeInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },
      currentModal: {
        title: "",
        note: "",
        task: {
          taskTitle: null,
          taskAssignee: null,
          taskDueDate: null,
          taskDescription: null,
          taskAssigneeOptions: [],
          taskAssigneeName: null,
          taskPriority: null,
          taskPriorityOptions: [
            { value: null, text: "Select Task Priority" },
            { value: 1, text: "Low" },
            { value: 2, text: "Medium" },
            { value: 3, text: "High" },
          ],
        },

        type: null,
        risk_id: null,
        isBulkSelection: false,
      },
      selectedParams: {},
      isFiltersActive: false,
      riskNameSearchValue: "",

      riskCriteriaOptions: [
        { value: null, text: "Select a value" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      selectedRiskCriteriaValue: null,

      riskScoreOptions: [
        { value: null, text: "--Risk Score Upto--" },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
      selectedRiskScore: null,

      residualRiskScoreOptions: [
        { value: null, text: "--Residual Risk Score Upto--" },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
      selectedResidualRiskScore: null,
    };
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.readQueryParamsAndGetRiskRegsiters();
      },
      deep: true,
      immediate: true,
    },
    filters: {
      handler: function (values) {
        let newValues = { ...values };
        delete newValues.page;
        // console.log("New Filters", newValues);

        newValues = Object.keys(newValues).map((key) => {
          return newValues[key];
        });
        if (newValues.every((val) => val == null)) {
          this.isFiltersActive = false;
        } else {
          this.isFiltersActive = true;
        }

        if (this.$route.query.page && this.$route.query.page != null) {
          const lastPage = Number(this.$route.query.page);
          const newPage = values.page;
          if (
            newPage > 1 &&
            lastPage == newPage &&
            !this.checkFiltersEmpty(values) &&
            this.checkFiltersDifferent(values) &&
            this.isInitialLoad == false
          ) {
            this.$set(this.filters, "page", 1);
            return;
          }
        }

        const query = this.getQueries(values);
        const path = this.$route.path;

        this.$router.replace({ path: path, query: query });

        // this.getAllRiskRegistersAndSetData(values);
      },
      deep: true,
    },

    isFiltersActive(newValue) {
      if (newValue == false) {
        this.risksWithFiltersSeleted = false;
      }
    },
  },
 
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.setMinDueDate();
      // this.getAllRiskRegistersAndSetData(this.getFilters());
      this.filters = this.getFilters();
    },

    checkFiltersDifferent(filters) {
      const newFilters = { ...filters };
      delete newFilters.page;
      const oldFilters = { ...this.$route.query };
      delete oldFilters.page;

      // console.log("New Filters: ", newFilters);
      // console.log("Old Filters: ", oldFilters);

      // Checks if keys match
      if (Object.keys(oldFilters).length != Object.keys(newFilters).length) {
        return true;
      } 
      // Checks if keys have different values
      else if (
        Object.keys(oldFilters).some(
          (key) => oldFilters[key] != newFilters[key]
        )
      ) {
        return true;
      }

      return false;
    },

    checkFiltersEmpty(filters) {
      const newFilters = { ...filters };
      delete newFilters.page;

      return Object.keys(newFilters)
        .map((k) => newFilters[k])
        .every((v) => v == null);
    },

    readQueryParamsAndGetRiskRegsiters() {
      const queryParams = this.$route.query;
      console.log("New Queries: ", queryParams);
      this.getAllRiskRegistersAndSetData(queryParams);
    },

    getRiskRegisterDetailsAndSetSidebar(registerId) {
      this.showOverlay = true;
      this.getRiskRegisterDetails(registerId)
        .then((res) => {
          console.log("Risk Register", res);
          this.selectedRisk = res.data.data;
          this.openInfoSidebar();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getQueries(filters) {
      const newQueries = {};

      // search: null,
      //   max_risk_value: null,
      //   max_risk_residual_score: null,
      //   risk_category: null,
      //   risk_mitigation_type: null,
      //   risk_id: null,

      if ("max_risk_value" in filters && filters["max_risk_value"] != null) {
        newQueries["max_risk_value"] = Number(filters["max_risk_value"]);
      }

      if (
        "max_risk_residual_score" in filters &&
        filters["max_risk_residual_score"] != null
      ) {
        newQueries["max_risk_residual_score"] = Number(
          filters["max_risk_residual_score"]
        );
      }

      if ("risk_category" in filters && filters["risk_category"] != null) {
        newQueries["risk_category"] = filters["risk_category"];
      }

      if (
        "risk_mitigation_type" in filters &&
        filters["risk_mitigation_type"] != null
      ) {
        newQueries["risk_mitigation_type"] = Number(
          filters["risk_mitigation_type"]
        );
      }

      if ("risk_id" in filters && filters["risk_id"] != null) {
        newQueries["risk_id"] = filters["risk_id"];
      }

      if ("page" in filters && filters["page"] != null) {
        newQueries["page"] = filters["page"];
      }
      if (
        "search" in filters &&
        filters["search"] != null &&
        filters["search"] != ""
      ) {
        newQueries["search"] = filters["search"];
      }

      return newQueries;
    },

    getFilters() {
      const queryParams = this.$route.query;
      const newAnimations = [];
      const newFilters = { ...this.filters };

      if ("max_risk_value" in queryParams) {
        newFilters["max_risk_value"] = Number(queryParams["max_risk_value"]);
        // this.setAnimation("max_risk_value");
        newAnimations.push("max_risk_value");
      }

      if ("max_risk_residual_score" in queryParams) {
        newFilters["max_risk_residual_score"] = Number(
          queryParams["max_risk_residual_score"]
        );
        // this.setAnimation("max_risk_residual_score");
        newAnimations.push("max_risk_residual_score");
      }

      if ("risk_category" in queryParams) {
        newFilters["risk_category"] = queryParams["risk_category"];
        // this.setAnimation("risk_category");
        newAnimations.push("risk_category");
      }

      if ("risk_mitigation_type" in queryParams) {
        newFilters["risk_mitigation_type"] = Number(
          queryParams["risk_mitigation_type"]
        );
        //this.setAnimation("risk_mitigation_type");
        newAnimations.push("risk_mitigation_type");
      }

      if ("risk_id" in queryParams) {
        newFilters["risk_id"] = queryParams["risk_id"];
      }

      if ("page" in queryParams) {
        newFilters["page"] = queryParams["page"];
      }

      if ("register_id" in queryParams) {
        this.getRiskRegisterDetailsAndSetSidebar(queryParams["register_id"]);
      }

      if ("search" in queryParams) {
        newFilters["search"] = queryParams["search"];
        //this.setAnimation("search");
        newAnimations.push("search");
      }

      this.setAnimation(newAnimations);
      // this.$router.replace({ query: null });
      return newFilters;
    },

    // setAnimation(filter) {
    //   this.highlightedFilter = filter;
    //   setTimeout(() => {
    //     this.highlightedFilter = null;
    //   }, 2700);
    // },

    setAnimation(filters) {
      this.highlightedFilters = filters;
      setTimeout(() => {
        this.highlightedFilters = [];
      }, 2700);
    },

    resetFilters() {
      this.isInitialLoad = true;
      const newFilters = {
        ...this.filters,
        search: null,
        max_risk_value: null,
        max_risk_residual_score: null,
        risk_category: null,
        risk_mitigation_type: null,
        risk_id: null,
        page:1,
      };
      this.filters = newFilters;
    },

    // hideBulkActionModal() {
    //   this.$refs["new-bulk-action-modal"].hide();
    // },
    // showBulkActionModal() {
    //   this.$refs["new-bulk-action-modal"].show();
    // },

    getActionColor(value) {
      switch (value) {
        case 1:
          return "danger";
        case 2:
          return "dark";
        case 3:
          return "success";
        case 4:
          return "warning";
        default:
          return "info";
      }
    },

    setMinDueDate() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDueDate = date;
    },

    handleMoreInfoClick(risk) {
      this.selectedRisk = risk;
      this.openInfoSidebar();
    },

    closeInfoSidebar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-info");
    },
    openInfoSidebar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-info");
    },
    getMonthFromNumber(data) {
      const num = new Date(data).getMonth();
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[num];
      return month;
    },
    handleFiltersActiveOrNot() {
      if (Object.keys(this.selectedParams).length === 0) {
        this.isFiltersActive = false;
        this.risksWithFiltersSeleted = false;
      } else {
        this.isFiltersActive = true;
      }
    },
    resetModalOptions() {
      const newTask = {
        taskTitle: null,
        taskAssignee: null,
        taskDueDate: null,
        taskDescription: null,
        taskAssigneeOptions: [],
        taskAssigneeName: null,
        taskPriority: null,
        taskPriorityOptions: [
          { value: null, text: "Select Task Priority" },
          { value: 1, text: "Low" },
          { value: 2, text: "Medium" },
          { value: 3, text: "High" },
        ],
      };
      this.$set(this.currentModal, "task", newTask);
      this.$set(this.currentModal, "note", "");
      this.$set(this.currentModal, "type", null);
      this.$set(this.currentModal, "risk_id", null);
      this.$set(this.currentModal, "isBulkSelection", false);
    },
    showModal() {
      if (this.currentModal.isBulkSelection) {
        this.currentModal.task.taskTitle = null;
        this.currentModal.task.taskDescription = null;
        this.currentModal.note = "";
        this.currentModal.task.taskAssigneeName = null;
        this.currentModal.task.taskAssigneeOptions = [];
        this.currentModal.task.taskAssignee = null;
        this.currentModal.task.taskDueDate = null;
        this.$refs["bulk-action-modal"].show();
      } else {
        if (this.currentModal.type == 4) {
          const riskIndex = this.risks.findIndex(
            (risk) => risk._id === this.currentModal.risk_id
          );
          if (riskIndex !== -1) {
            this.currentModal.task.taskTitle =
              this.risks[riskIndex].risk.name + " Mitigate Task";
            this.currentModal.task.taskDescription = this.risks[riskIndex].risk
              .treatment_plan
              ? this.risks[riskIndex].risk.treatment_plan
              : "";
          } else {
            this.currentModal.task.taskTitle = null;
            this.currentModal.task.taskDescription = null;
          }
        } else {
          this.currentModal.task.taskTitle = null;
          this.currentModal.task.taskDescription = null;
        }
        this.currentModal.note = "";
        this.currentModal.task.taskAssigneeName = null;
        this.currentModal.task.taskAssigneeOptions = [];
        this.currentModal.task.taskAssignee = null;
        this.currentModal.task.taskDueDate = null;
        this.$refs["bulk-action-modal"].show();
      }
    },
    hideModal() {
      this.$refs["bulk-action-modal"].hide();
    },
    handleAddNewTaskClick() {
      this.showTaskForm = false;
    },
    toggleTableRow(id) {
      const index = this.expandedList.indexOf(id);
      if (index > -1) {
        this.expandedList.splice(index, 1);
      } else {
        this.expandedList = [id];
      }
    },
    getVariant(status) {
      switch (status) {
        case 1:
          return "info";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "danger";
        case 5:
          return "primary";
        default:
          return "info";
      }
    },
    getActionValue(value) {
      switch (value) {
        case 1:
          return "Avoid";
        case 2:
          return "Transfer";
        case 3:
          return "Accept";
        case 4:
          return "Mitigate";
        default:
          return "Avoid";
      }
    },
    getActionPastTenseValue(value) {
      switch (value) {
        case 1:
          return "Avoided";
        case 2:
          return "Transfered";
        case 3:
          return "Accepted";
        case 4:
          return "Mitigated";
        default:
          return "Avoided";
      }
    },
    getScoreValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Critical";
        default:
          return "Very Low";
      }
    },
    getImpactValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Negligible";
        case 2:
          return "Low";
        case 3:
          return "Moderate";
        case 4:
          return "High";
        case 5:
          return "Very High";
        default:
          return "Negligible";
      }
    },
    getLikelihoodValueFromNumber(num) {
      switch (num) {
        case 1:
          return "Not Occur";
        case 2:
          return "Remote";
        case 3:
          return "Unlikely";
        case 4:
          return "Possible";
        case 5:
          return "Likely";
        default:
          return "Not Occur";
      }
    },

    // ---------Single Actions--------------
    handleTransferClick(risk_id) {
      this.resetModalOptions();
      this.currentModal.title = "Transfer";
      this.currentModal.type = 2;
      this.currentModal.risk_id = risk_id;
      this.showTaskForm = true;
      this.showModal();
    },
    handleAvoidClick(risk_id) {
      this.resetModalOptions();
      this.currentModal.title = "Avoid";
      this.currentModal.type = 1;
      this.currentModal.risk_id = risk_id;
      this.showTaskForm = true;
      this.showModal();
    },
    handleAcceptClick(risk_id) {
      this.resetModalOptions();
      this.currentModal.title = "Accept";
      this.currentModal.type = 3;
      this.currentModal.risk_id = risk_id;
      this.showTaskForm = false;
      this.showModal();
    },
    handleMitigateClick(risk_id) {
      this.resetModalOptions();
      this.currentModal.title = "Mitigate";
      this.currentModal.type = 4;
      this.currentModal.risk_id = risk_id;
      this.showTaskForm = true;
      this.showModal();
    },

    //------------BULK ACTIONS-------------
    handleBulkTransferClick() {
      this.resetModalOptions();
      this.currentModal.title = "Transfer";
      this.currentModal.type = 2;
      this.currentModal.isBulkSelection = true;
      this.showTaskForm = true;
      this.showModal();
    },
    handleBulkAvoidClick() {
      this.resetModalOptions();
      this.currentModal.title = "Avoid";
      this.currentModal.type = 1;
      this.currentModal.isBulkSelection = true;
      this.showTaskForm = true;
      this.showModal();
    },
    handleBulkAcceptClick() {
      this.resetModalOptions();
      this.currentModal.title = "Accept";
      this.currentModal.type = 3;
      this.currentModal.isBulkSelection = true;
      this.showTaskForm = false;
      this.showModal();
    },
    handleBulkMitigateClick() {
      this.resetModalOptions();
      this.currentModal.title = "Mitigate";
      this.currentModal.type = 4;
      this.currentModal.isBulkSelection = true;
      this.showTaskForm = true;
      this.showModal();
    },

    handleRiskSelection(value, risk_id) {
      if (value === true) {
        this.selectedRisks.push(risk_id);
        if (this.selectedRisks.length === this.risks.length) {
          this.allRisksSelected = true;
        }
      } else {
        const newRisks = this.selectedRisks.filter((risk) => risk !== risk_id);
        this.selectedRisks = newRisks;
        this.allRisksSelected = false;
      }
    },
    handleBulkRiskSelection(value) {
      if (value === true) {
        this.selectedRisks = [];
        this.risks.map((risk) => {
          this.selectedRisks.push(risk._id);
          risk.risk_selected = true;
        });
      } else {
        this.selectedRisks = [];
        this.risks.map((risk) => {
          risk.risk_selected = false;
        });
      }
    },

    handleBulkActionSubmit() {
      this.isModalLoading = true;

      // Handling Bulk Actions
      if (this.currentModal.isBulkSelection) {
        let createBulkActionParams = {
          ...this.currentModal,
          risk_ids: this.isFiltersActive ? [] : this.selectedRisks,
        };
        if (this.isFiltersActive) {
          createBulkActionParams["filters"] = this.filters;
        }
        this.createBulkRiskAction(createBulkActionParams)
          .then((res) => {
            this.hideModal();
            // this.load();
            this.readQueryParamsAndGetRiskRegsiters();
            this.handleResponse(res);
            this.resetModalOptions();
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          })
          .finally(() => {
            this.isModalLoading = false;
          });
      }
      // Handling Single Risk Action
      else {
        this.createRiskAction(this.currentModal)
          .then((res) => {
            // this.load();
            this.readQueryParamsAndGetRiskRegsiters();
            this.hideModal();
            this.handleResponse(res);
            //Resetting the current modal state
            this.resetModalOptions();
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          })
          .finally(() => {
            this.isModalLoading = false;
          });
      }
    },
    clearFilters() {
      this.resetFilters();
      this.selectedParams = {};
      this.isFiltersActive = false;
      this.selectedRiskScore = null;
      this.selectedResidualRiskScore = null;
      this.riskNameSearchValue = "";
      this.risksWithFiltersSeleted = false;
    },
    getAllRiskRegistersAndSetData(params) {
      this.showOverlay = true;
      this.getAllRisksRegisters(params)
        .then((res) => {
          this.isInitialLoad = false;
          this.allRisksSelected = false;
          const risks = res.data.data.data.map((register) => {
            const newRiskRegister = { ...register }; // A single soruce of Data
            // Getting values from the Risk Assessment Response Object
            if (
              (register.risk_assessment_response &&
                register.risk_assessment_response != null &&
                Object.keys(register.risk_assessment_response).length > 0) ||
              (register.asset_risk &&
                register.asset_risk != null &&
                Object.keys(register.asset_risk).length > 0)
            ) {
              ["residual_risk_value", "risk_value"].forEach((key) => {
                // First Checks for the keys in the Main Object
                // The Keys in this object are of topmost priority
                if (register[key] && register[key] != null) {
                  newRiskRegister[key] = register[key];
                }
                // Else Checks for the keys in the risk_assessment_response
                else if (
                  register.risk_assessment_response &&
                  register.risk_assessment_response != null &&
                  register.risk_assessment_response[key] &&
                  register.risk_assessment_response[key] != null
                ) {
                  newRiskRegister[key] = register.risk_assessment_response[key];
                }
                // Else Checks for the keys in the asset_risk
                else if (
                  register.asset_risk &&
                  register.asset_risk != null &&
                  register.asset_risk[key] &&
                  register.asset_risk[key] != null
                ) {
                  newRiskRegister[key] = register.asset_risk[key];
                } else {
                  newRiskRegister[key] = null;
                }
              });
              // console.log("Risk Register: ", newRiskRegister);
              return newRiskRegister;
            }
          });
          for (var i = 0; i <= risks.length; i++) {
            if (risks[i]) {
              if (risks[i].risk_actions.length > 0) {
                Object.assign(risks[i], {
                  action_select_box_text: this.getActionPastTenseValue(
                    parseInt(
                      risks[i].risk_actions[risks[i].risk_actions.length - 1]
                        .type,
                      10
                    )
                  ),
                });
              } else {
                Object.assign(risks[i], {
                  action_select_box_text: "Select Action",
                });
              }
              Object.assign(risks[i], { risk_selected: this.allRisksSelected });
              if (risks[i].asset_risk !== null) {
                Object.assign(risks[i], {
                  assessment_values: risks[i].asset_risk,
                });
              } else if (risks[i].risk_assessment_response !== null) {
                Object.assign(risks[i], {
                  assessment_values: risks[i].risk_assessment_response,
                });
              }
            }
          }
          this.risks = risks;
          // this.currentPage = res.data.data.current_page;
          this.rows = res.data.data.last_page * res.data.data.per_page;
          this.perPage = res.data.data.per_page;
          this.from = res.data.data.from;
          this.to = res.data.data.to;
          this.totalRisks = res.data.data.total;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    handleRiskRegisterDetailsClick(riskId, registerId) {
      this.$router.push({
        name: "risk-details",
        params: { riskId: riskId, registerId: registerId },
      });
    },

    getAssigneeBySearchPhrase(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: { search: searchPhrase, limit: 5 },
        };
        this.timeout = setTimeout(() => {
          this.$http(options)
            .then((res) => {
              this.currentModal.task.taskAssigneeOptions = [];
              let assignees = res.data.data.data;
              this.currentModal.task.taskAssigneeOptions.push({
                name: "assignees",
                data: assignees,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.currentModal.task.taskAssigneeOptions = [];
        this.currentModal.task.taskAssignee = null;
        this.currentModal.task.taskAssigneeName = null;
      }
    },
    onAssigneeSelected(assignee) {
      this.currentModal.task.taskAssignee = assignee.item;
      this.currentModal.task.taskAssigneeName = `${assignee.item.firstname} ${assignee.item.lastname}`;
    },
    closeSidebar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar(id) {
      let newRiskIndex = this.risks.findIndex((risk) => risk._id == id);
      if (newRiskIndex != -1) {
        this.taskSelectedRisk = this.risks[newRiskIndex];
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style scoped>
.table tr td:nth-child(5) {
  width: 350px; /*Custom your width*/
}

.custom-table-data {
  position: relative;
}
.custom-table-data:before {
  content: " ";
  visibility: hidden;
}
.custom-table-data span {
  position: absolute;
  left: 23px;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-button {
  min-width: 70px;
}

.expanded-row {
  border-top: 3px solid #6e6b7b;
  border-left: 3px solid #6e6b7b;
  border-right: 3px solid #6e6b7b;
}
.expanded-row-section2 {
  border-bottom: 3px solid #6e6b7b;
  border-left: 3px solid #6e6b7b;
  border-right: 3px solid #6e6b7b;
  transition: all 0.5s ease-in-out;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-todo.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.55rem 0.75rem;
}
</style>

<style scoped>
.highlighted {
  animation: breathingEffect 1s infinite ease-in-out; /* Apply breathing animation for 7 seconds */
}

@keyframes breathingEffect {
  0% {
    border: 1px solid #00cfe8;
    box-shadow: 0 0 10px 10px #00d1e883;
  }
  50% {
    border: 1px solid transparent;
    box-shadow: none;
  }
  100% {
    border: 1px solid #00cfe8;
    box-shadow: 0 0 10px 10px #00d1e883;
  }
}
</style>
