var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"card",staticStyle:{"box-shadow":"none !important"}},[_c('div',{staticClass:"card-header border-bottom d-flex"},[(_vm.risk.risk && _vm.risk.risk.name && _vm.risk.risk.name !== null)?_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-start",staticStyle:{"width":"80%"}},[_c('h4',{staticClass:"mb-0"},[_vm._v("Risk Details")])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),_c('div',{staticClass:"card-body pt-1 d-flex flex-column align-items-start justify-content-start w-100"},[_c('div',{staticClass:"d-flex justify-content-end w-100"},[_c('b-button',{staticStyle:{"float":"right"},attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.handleRiskRegisterDetailsClick(_vm.risk._id, _vm.risk.risk_id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ExternalLinkIcon"}}),_vm._v("More Details")],1)],1),_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start mb-1 w-100"},[_c('h6',{staticClass:"text-primary"},[_vm._v("Name")]),_c('div',{staticClass:"rounded border d-flex align-items-start justify-content-start w-100 p-1"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start w-100"},[(
                _vm.risk.risk &&
                _vm.risk.risk != null &&
                _vm.risk.risk.name &&
                _vm.risk.risk.name != null
              )?_c('p',{staticClass:"font-weight-bold mb-0"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.risk.risk.name))])]):_vm._e(),(
                _vm.risk.risk &&
                _vm.risk.risk != null &&
                _vm.risk.risk.description &&
                _vm.risk.risk.description != null
              )?_c('small',{staticClass:"mt-75"},[_vm._v(" "+_vm._s(_vm.risk.risk.description)+" ")]):_vm._e()])])]),_c('b-card',{staticClass:"bg-dark-blue h-100 w-100",attrs:{"align":"center"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-list-group',{staticClass:"list-group-circle text-left bg-transparent"},[_c('b-list-group-item',{staticClass:"bg-transparent"},[_c('b',[_vm._v("Category:")]),_c('span',[_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.risk.asset_category ? _vm.risk.asset_category.name : ""))])],1)]),_c('b-list-group-item',{staticClass:"bg-transparent"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('b',[_vm._v("Risk Score:")]),_c('span',{staticClass:"ml-1 d-flex align-items-center justify-content-start"},[(
                        _vm.risk.risk_value &&
                        _vm.risk.risk_value != null &&
                        _vm.risk.risk_value != ''
                      )?_c('b-badge',{staticStyle:{"width":"120px"},attrs:{"variant":_vm.getMappedVariant(_vm.risk.risk_value)}},[_vm._v(_vm._s(_vm.getMappedTextValue(_vm.risk.risk_value))+" "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","size":"sm"}},[_vm._v(_vm._s(_vm.risk.risk_value))])],1):(
                        _vm.risk.assessment_values.risk_value &&
                        _vm.risk.assessment_values.risk_value != null &&
                        _vm.risk.assessment_values.risk_value != ''
                      )?_c('b-badge',{staticStyle:{"width":"120px"},attrs:{"variant":_vm.getMappedVariant(_vm.risk.assessment_values.risk_value)}},[_vm._v(_vm._s(_vm.getMappedTextValue(_vm.risk.assessment_values.risk_value))+" "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","size":"sm"}},[_vm._v(_vm._s(_vm.risk.assessment_values.risk_value))])],1):_vm._e()],1)])]),_c('b-list-group-item',{staticClass:"bg-transparent"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('b',[_vm._v("Residual Risk Score:")]),_c('span',{staticClass:"ml-1 d-flex align-items-center justify-content-start"},[(
                        _vm.risk.residual_risk_value &&
                        _vm.risk.residual_risk_value != null &&
                        _vm.risk.residual_risk_value != ''
                      )?_c('b-badge',{staticStyle:{"width":"120px"},attrs:{"variant":_vm.getMappedVariant(_vm.risk.residual_risk_value)}},[_vm._v(_vm._s(_vm.getMappedTextValue(_vm.risk.residual_risk_value))+" "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","size":"sm"}},[_vm._v(_vm._s(_vm.risk.residual_risk_value))])],1):(
                        _vm.risk.assessment_values.residual_risk_value &&
                        _vm.risk.assessment_values.residual_risk_value != null &&
                        _vm.risk.assessment_values.residual_risk_value != ''
                      )?_c('b-badge',{staticStyle:{"width":"120px"},attrs:{"variant":_vm.getMappedVariant(
                          _vm.risk.assessment_values.residual_risk_value
                        )}},[_vm._v(_vm._s(_vm.getMappedTextValue( _vm.risk.assessment_values.residual_risk_value ))+" "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","size":"sm"}},[_vm._v(_vm._s(_vm.risk.assessment_values.residual_risk_value))])],1):_vm._e()],1)])])],1)],1)])]),_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-between mb-2 w-100"},[_c('h6',{staticClass:"text-primary"},[_vm._v("Threats")]),(_vm.risk.threat)?_c('div',{staticClass:"rounded border d-flex align-items-center justify-content-between w-100"},[_c('table',{staticClass:"table table-sm",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}}),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Name")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader","scope":"col"}},[_vm._v(" Value ")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[_c('tr',[_c('td',{attrs:{"role":"cell"}},[_vm._v("1")]),_c('td',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(_vm.risk.threat ? _vm.risk.threat.name : "")+" ")]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[(
                        _vm.risk.threat.threat_value &&
                        _vm.risk.threat.threat_value != null &&
                        _vm.risk.threat.threat_value != ''
                      )?_c('b-badge',{staticStyle:{"width":"120px"},attrs:{"variant":_vm.getVariant(_vm.risk.threat.threat_value)}},[_vm._v(_vm._s(_vm.getTextValue(_vm.risk.threat.threat_value))+" "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","size":"sm"}},[_vm._v(_vm._s(_vm.risk.threat.threat_value))])],1):_vm._e()],1)])])])])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-between mb-2 w-100"},[_c('h6',{staticClass:"text-primary"},[_vm._v("Vulnerabilities")]),(_vm.risk.vulnerabilities && _vm.risk.vulnerabilities != null)?_c('div',{staticClass:"rounded border d-flex align-items-center justify-content-between w-100"},[_c('table',{staticClass:"table table-sm",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}}),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Name")]),_c('th',{staticClass:"text-center",attrs:{"role":"columnheader","scope":"col"}},[_vm._v(" Value ")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},[(
                  _vm.risk.vulnerabilities &&
                  _vm.risk.vulnerabilities != null &&
                  _vm.risk.vulnerabilities.length > 0
                )?_vm._l((_vm.risk.vulnerabilities),function(vuln,i){return _c('tr',{key:i,attrs:{"role":"row"}},[_c('td',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('td',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(vuln.name)+" ")]),_c('td',{attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100"},[_c('b-badge',{staticStyle:{"width":"120px"},attrs:{"variant":_vm.getVariant(vuln.vulnerability_value)}},[_vm._v(_vm._s(_vm.getTextValue(vuln.vulnerability_value))+" "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","size":"sm"}},[_vm._v(_vm._s(vuln.vulnerability_value))])],1)],1)])])}):[_c('empty-table-section',{attrs:{"title":"No Vulnerabilities"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(" It seems like there are no vulnerabilities at the moment. ")])]},proxy:true}],null,false,1779899372)})]],2)])]):_vm._e()]),(_vm.risk.risk.treatment_plan && _vm.risk.risk.treatment_plan !== null)?_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start mb-1 w-100"},[_c('h6',{staticClass:"text-primary"},[_vm._v("Treatment Plan")]),_c('div',{staticClass:"rounded border d-flex align-items-start justify-content-start w-100 p-1"},[_c('div',{staticClass:"d-flex align-items-start justify-content-start w-100"},[_c('p',{staticClass:"text-left font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.risk.risk.treatment_plan)+" ")])])])]):_vm._e(),_c('div',{staticClass:"w-100 mt-2"},[_c('b-tabs',{attrs:{"content-class":"mt-1","justified":""}},[_c('b-tab',{attrs:{"title":"Controls","lazy":"","active":""}},[_c('div',{staticClass:"w-100 h-auto cursor-pointer",on:{"click":function () {
                  _vm.$router.push({
                    name: 'risk-details',
                    params: { riskId: _vm.risk._id, registerId: _vm.risk.risk_id },
                    query: {
                      tabIndex: 1,
                    },
                  });
                }}},[_c('controls-mapping-section',{attrs:{"registerId":_vm.risk.risk_id}})],1)]),_c('b-tab',{attrs:{"title":"Tasks","lazy":""}},[_c('tasks',{attrs:{"registerId":_vm.risk._id}})],1),_c('b-tab',{attrs:{"title":"Discussions","lazy":""}},[_c('risk-comments-section',{attrs:{"registerId":_vm.risk._id}})],1),_c('b-tab',{attrs:{"title":"Logs","lazy":""}},[_c('div',{staticClass:"w-100 h-auto cursor-pointer",on:{"click":function () {
                  _vm.$router.push({
                    name: 'risk-details',
                    params: { riskId: _vm.risk._id, registerId: _vm.risk.risk_id },
                    query: {
                      tabIndex: 4,
                    },
                  });
                }}},[_c('logs',{attrs:{"registerId":_vm.risk._id}})],1)])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }