var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"w-100 h-auto"},[_c('table',{staticClass:"table table-condensed w-100",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}}),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Message")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("User")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Activity On")])])]),_c('tbody',[(_vm.logs && _vm.logs != null && _vm.logs.length > 0)?[_vm._l((_vm.logs),function(log,i){return [_c('tr',{key:log._id,attrs:{"role":"row"}},[_c('th',{staticClass:"text-center",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('td',{staticStyle:{"max-width":"12vw"},attrs:{"role":"cell"}},[(
                    log._source.log_message &&
                    log._source.log_message !== null
                  )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.v-primary",modifiers:{"top":true,"v-primary":true}}],staticClass:"w-100 text-truncate",attrs:{"title":log._source.log_message},domProps:{"innerHTML":_vm._s(log._source.log_message)}}):_vm._e()]),_c('td',{staticStyle:{"max-width":"6.5vw"},attrs:{"role":"cell"}},[(
                    log._source.performed_by &&
                    log._source.performed_by !== null
                  )?[_c('p',{staticClass:"mb-0 font-weight-bold text-truncate"},[_vm._v(" "+_vm._s(log._source.performed_by)+" ")])]:_vm._e()],2),_c('td',{staticStyle:{"max-width":"6.5vw"},attrs:{"role":"cell"}},[(
                    log._source.activity_time &&
                    log._source.activity_time !== null
                  )?[_vm._v(" "+_vm._s(_vm._f("formatdatetime")(log._source.activity_time))+" ")]:_vm._e()],2)])]})]:[_c('empty-table-section',{attrs:{"title":"Data Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(" It seems there is no data at the moment. ")])]},proxy:true}])})]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }