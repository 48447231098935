<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="w-100 h-auto">
      <table role="table" class="table table-condensed w-100">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col"></th>
            <th role="columnheader" scope="col">Message</th>
            <th role="columnheader" scope="col">User</th>
            <th role="columnheader" scope="col">Activity On</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="logs && logs != null && logs.length > 0">
            <template v-for="(log, i) in logs">
              <tr role="row" :key="log._id">
                <th scope="row" class="text-center">
                  {{ i + 1 }}
                </th>

                <td role="cell" style="max-width: 12vw">
                  <div
                    v-if="
                      log._source.log_message &&
                      log._source.log_message !== null
                    "
                    :title="log._source.log_message"
                    v-b-tooltip.top.v-primary
                    class="w-100 text-truncate"
                    v-html="log._source.log_message"
                  ></div>
                  <!-- <div
                    class="w-100 d-flex flex-column align-items-start justify-content-center"
                  >
                    <template
                      v-if="
                        log._source.log_message &&
                        log._source.log_message !== null
                      "
                    >
                      <span class="mb-0 text-truncate">{{ log._source.log_message }}</span>
                    </template>
                    <template
                      v-if="
                        log._source.activity_time &&
                        log._source.activity_time !== null
                      "
                    >
                      <small class="mt-50 rounded border p-25"
                        >Activity Time: &nbsp;<b-badge
                          variant="light-primary"
                          >{{ log._source.activity_time | formatdate }}</b-badge
                        ></small
                      >
                    </template>
                  </div> -->
                </td>

                <td role="cell" style="max-width: 6.5vw">
                  <template
                    v-if="
                      log._source.performed_by &&
                      log._source.performed_by !== null
                    "
                  >
                    <p class="mb-0 font-weight-bold text-truncate">
                      {{ log._source.performed_by }}
                    </p>
                  </template>
                </td>
                <td role="cell" style="max-width: 6.5vw">
                  <template
                    v-if="
                      log._source.activity_time &&
                      log._source.activity_time !== null
                    "
                  >
                    {{ log._source.activity_time | formatdatetime }}
                  </template>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <empty-table-section title="Data Empty">
              <template #content>
                <p class="text-center">
                  It seems there is no data at the moment.
                </p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>
    </div>
  </b-overlay>
</template>

<script>
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import { BFormCheckbox, BBadge, BOverlay, VBTooltip } from "bootstrap-vue";
import moment from "moment";
import ResponseMixins from "@/mixins/ResponseMixins";
import LogMixins from "@/mixins/LogMixins";

export default {
  mixins: [ResponseMixins, LogMixins],
  components: { EmptyTableSection, BFormCheckbox, BBadge, BOverlay },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      logs: [],
      showOverlay: false,
      filters: {
        page: 1,
      },
    };
  },

  filters: {
    formatdatetime: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YY, h:mm A");
      }
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getLogsAndSetData({
        activity_on: "App\\Models\\RiskRegister",
        ref_id: this.registerId,
      });
    },

    getLogsAndSetData(params) {
      this.showOverlay = true;
      this.getLogs(params)
        .then((res) => {
          console.log("Logs: ", res);
          this.logs = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
