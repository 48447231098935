<template>
  <div
    
    class="
      border-light
      bg-light-secondary
      py-1
      px-3
      rounded
      w-100
      d-flex
      flex-row
      align-items-center
      justify-content-between
    "
  >
    <div
      style="width: 76%"
      class="d-flex align-items-center justify-content-center"
    >
      <vue-slider
        v-model="model"
        name="range_slider"
        :min="min"
        :max="max"
        :tooltip="'always'"
        :tooltip-style="{
          backgroundColor: mapColorToValue(),
        }"
        width="100%"
        direction="ltr"
        :disabled="disabled"
        :dot-style="{ backgroundColor: mapColorToValue() }"
        :process-style="{ backgroundColor: mapColorToValue() }"
      ></vue-slider>
    </div>
    <div
      style="width: 20%"
      class="d-flex flex-column align-items-center justify-content-around p2"
    >
      <div
        :class="'bg-' + mapVariantNameToValue()"
        class="
          rounded
          d-flex
          flex-column
          align-items-center
          justify-content-between
        "
      >
        <h4
          :class="mapTextClassToValue()"
          class="mb-1 mt-75 font-weight-bolder"
        >
          {{ mapValueName() }}
        </h4>
        <div class="d-flex align-items-center justify-content-start">
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <b-form-input
              v-model="model"
              type="number"
              name="range_input"
              style="width: 60%"
              :disabled="disabled"
              :class="[mapTextClassToValue(), mapBorderClassToValue()]"
              class="mx-auto mb-75 text-center font-weight-bolder"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { BFormInput } from "bootstrap-vue";

export default {
  components: {
    VueSlider,
    BFormInput,
  },
  // data() {
  //   return {
  //     model: this.value,
  //   };
  // },

  // watch: {
  //   model(currentValue) {
  //     this.$emit("input", currentValue);
  //   },
  // },

  computed:{
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    console.log("value", this.model);
    console.log("min", this.min);
    console.log("max", this.max);
  },

  methods: {
    mapColorToValue() {
      if (this.model <= 11) {
        return "#28C76F";
      } else if (this.model > 11 && this.model <= 17) {
        return "#00CFE8";
      } else if (this.model > 17 && this.model <= 35) {
        return "#7267f0";
      } else if (this.model > 35 && this.model <= 74) {
        return "#FF9F43";
      } else if (this.model > 74 && this.model <= 100) {
        return "#EA5455";
      } else {
        return "#28C76F";
      }
    },

    mapTextClassToValue() {
      if (this.model <= 11) {
        return "text-success";
      } else if (this.model > 11 && this.model <= 17) {
        return "text-info";
      } else if (this.model > 17 && this.model <= 35) {
        return "text-primary";
      } else if (this.model > 35 && this.model <= 74) {
        return "text-warning";
      } else if (this.model > 74 && this.model <= 100) {
        return "text-danger";
      } else {
        return "text-success";
      }
    },
    mapBorderClassToValue() {
      if (this.model <= 11) {
        return "border-success";
      } else if (this.model > 11 && this.model <= 17) {
        return "border-info";
      } else if (this.model > 17 && this.model <= 35) {
        return "border-primary";
      } else if (this.model > 35 && this.model <= 74) {
        return "border-warning";
      } else if (this.model > 74 && this.model <= 100) {
        return "border-danger";
      } else {
        return "border-success";
      }
    },

    mapVariantNameToValue() {
      if (this.model <= 11) {
        return "light-success";
      } else if (this.model > 11 && this.model <= 17) {
        return "light-info";
      } else if (this.model > 17 && this.model <= 35) {
        return "light-primary";
      } else if (this.model > 35 && this.model <= 74) {
        return "light-warning";
      } else if (this.model > 74 && this.model <= 100) {
        return "light-danger";
      } else {
        return "light-success";
      }
    },

    mapValueName() {
      if (this.model <= 11) {
        return "Very Low";
      } else if (this.model > 11 && this.model <= 17) {
        return "Low";
      } else if (this.model > 17 && this.model <= 35) {
        return "Medium";
      } else if (this.model > 35 && this.model <= 74) {
        return "High";
      } else if (this.model > 74 && this.model <= 100) {
        return "Very High";
      } else {
        return "Very Low";
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>